import { Page } from "@jobber/components/Page";
import { Spinner } from "@jobber/components/Spinner";
import { Text } from "@jobber/components/Text";
import ClientsTable from "components/ClientsTable";
import EmptyStateCard from "components/EmptyStateCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClients } from "services";

function Home() {
  const [clients, setClients] = useState([]);
  const [isFetchingClients, setIsFetchingClients] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsFetchingClients(true);
        const { data } = await getClients();
        setClients(data.clients);
        setIsFetchingClients(false);
      } catch (error) {
        setIsFetchingClients(false);
        navigate("/auth");
      }
    })();
  }, []);
  return (
    <Page title="Kitchen Makeovers Scheduling App" width="fill">
      <div style={{ maxWidth: "854px" }}>
        <Text size="large">
          This is the first test page of the scheduling app while it is in development.
        </Text>
        <br />
        <Text size="large">
          Below you can see a list of the Clients in the connected Jobber
          account. It takes about a minute to load.
        </Text>
      </div>
      {isFetchingClients ? (
        <Spinner size="small" />
      ) : clients.length > 0 ? (
        <ClientsTable clients={clients} />
      ) : (
        <EmptyStateCard />
      )}
    </Page>
  );
}

export default Home;
